<template>
  <div>
    <div class="font-stolzl min-h-screen">
    
      <!-- SPACE-->
      <div id="space" class="md:h-16"></div>
      <!-- SPACE-->
      
      <!-- HEADING-->
      <div  class="w-auto h-auto overflow-hidden w-full flex flex-row justify-between items-end mb-5 px-4 mt-5 md:mt-28 md:px-8">
        <div :ref="setRef" data-anim="0" class="font-light text-coolGray-900    text-5xl opacity-0">Team</div>
        <div :ref="setRef" data-anim="0" class="hidden opacity-0 md:flex flex-row justify-center items-center">
          <div class="text-coolGray-900 font-light mr-2">Filter</div>
          <div v-for="filter in filter" class="transition duration-300 mr-2 ease-out bg-coolGray-400 hover:bg-coolGray-900 text-coolGray-50  rounded-lg text-xs font-normal px-2 py-1 tracking-widest cursor-pointer select-none" 
            :class="{'transition duration-300 ease-out bg-coolGray-900' : currentFilter.includes(filter.filterVal)}"
            @click="addFilter(filter)">
            {{text[filter.textId].toUpperCase()}}
          </div>
        </div>
      </div>
      <!-- HEADING-->
      
      <!-- TEAM MEMBERS-->
      <div v-if="filteredTeamMembers" class="w-full grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16 px-4 md:px-8 mt-5 md:mt-2 mb-16">
        <div v-for="member in filteredTeamMembers">
          <div v-if="member?.images?.length > 0" :ref="setRef" data-anim="0" 
            class="relative opacity-0 bg-coolGray-900 w-full cursor-pointer h-66 group" 
            @click="navigateTo(member.slug)">
            <div class="transition duration-500 delay-300 absolute z-30 bottom-5 left-5 md:opacity-0 group-hover:opacity-100">
              <div class="font-bold text-lg text-coolGray-50">{{member.name.toUpperCase()}}</div>
            </div>
            <div class="transition duration-1000 absolute w-full h-full z-10 bg-coolGray-900 opacity-40 group-hover:opacity-0"></div>
            <div v-if="member?.tags?.length > 0" class="absolute top-0  mt-4 md:mt-5 pl-5 md:pl-5 w-full flex flex-row justify-start items-center  z-50">
              <div v-for="tag in member.tags">
                <div class="bg-coolGray-50 text-coolGray-900 mr-1 rounded-lg text-xs font-normal px-2 py-1 tracking-widest">{{tag.toUpperCase()}}</div>
              </div>
            </div>
            <div class="absolute w-full h-full overflow-hidden">
              <div class="transition duration-1000 bg-blend-luminosity bg-coolGray-700 ease-in-out transform-gpu group-hover:scale-105 w-full h-full bg-cover bg-no-repeat bg-center" :style="{ backgroundImage: 'url(' + member.images[0].url + ')' }" ></div>
            </div>
            
          </div>
        </div>
      </div>
      <!-- TEAM MEMBERS-->
      
    </div>
  
    <!-- COMPONENTS-->
    <Footer />
    <getAllTeamMemberQuery />
    <getAllTeamMemberImagesQuery />
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';

import Footer from '../components/footer.vue';
import getAllTeamMemberQuery from '../graphql/graphcms/getAllTeamMemberQuery.vue';
import getAllTeamMemberImagesQuery from '../graphql/graphcms/getAllTeamMemberImagesQuery.vue';

import scrollToTop from '../mixins/scrollToTop.vue';
import intersectionObserver from '../mixins/intersectionObserver.vue';

export default {
  name: 'Team',
  components: { Footer, getAllTeamMemberQuery, getAllTeamMemberImagesQuery },
  mixins: [ scrollToTop, intersectionObserver ],
 
  computed: {
    //graphql
    teamMembers(){
      return this.$store.state.data.allTeamMembers;
    },
    teamMemberImages(){
      return this.$store.state.data.allTeamMemberImages;
    },
    //data
    text(){
      return this.$store.state.lang.team[this.$store.state.lang.currentLang];
    },
    currentFilter(){
      return this.$store.state.data.currentFilter;
    },
    filter(){
      return this.$store.state.data.filter;
    },
    constructedTeamMembers(){
      var constructed = [];
      var that = this;
      if(this.teamMemberImages && this.teamMembers){
      this.teamMembers.teams.forEach((member,i) => {
        constructed.push({
          images: that.teamMemberImages.teams[i].images,
          tags: member.tags,
          slug: member.slug,
          name: member.name
        });
      })
      return constructed;
      }
    },
    filteredTeamMembers(){
      if(this.currentFilter.length < 1){
        return this.constructedTeamMembers;
      } else {
        var that = this;
        var filtered = [];
        this.currentFilter.forEach(filter => {
          that.constructedTeamMembers.forEach(member => {
            member.tags.forEach(tag => {
              if(tag.includes(filter)){
                filtered.push(member);
              }
            });
          });
        })
        return filtered;
      }
    }
  },
  
  mounted(){
    document.title = "Athlet:innen, Routersetter:innen und Fotograf:innen aus dem rewhite-team";
    document.querySelector('meta[name="description"]').setAttribute("content", "Wir wären nichts ohne die Community. Egal ob Anfänger, Wettkampfathletin oder Routesetter - wir alle sind im selben Team.");
  },
  
  beforeUnmount(){
    this.$store.commit('resetTeamFilter');
  },
  
  methods: {

    addFilter(filter){
      this.$store.commit('setTeamFilter', filter.filterVal);
    },
    
    navigateTo(slug){
      this.$router.push({ name: 'TeamMember', params: { slug: slug } });
    },
      
  }
}
    
</script>