<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name:"getAllTeamMemberQuery",
  
  watch: {
    allTeamMembers() {
      this.$store.commit('graphqlData', { item: 'allTeamMembers', result: this.allTeamMembers });
     },
  },
    
  setup() {
    const store = useStore();
    
    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang)
    });
    
    useClient({
      url: process.env.VUE_APP_GRAPHCMS_ATHLETE_URL,
      cachePolicy: 'network-only'
    });
    
    const getAllTeamMemberQuery = gql`
      query MyQuery($lang:Locale!) {
        teams(locales: [$lang]) {
          slug
          name
          tags
        }
      }
    `;
    
    const { data: allTeamMembers } = useQuery({
      query: getAllTeamMemberQuery,
      variables
    });
    
    return { allTeamMembers };
  },
}
</script>