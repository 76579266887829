<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name:"getAllTeamMemberImagesQuery",
  
  watch: {
    allTeamMemberImages() {
      this.$store.commit('graphqlData', { item: 'allTeamMemberImages', result: this.allTeamMemberImages });
     },
  },
    
  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_GRAPHCMS_ATHLETE_URL,
    });
    
    const getAllTeamMemberImagesQuery = gql`
      query MyQuery {
        teams {
          images(first: 1) {
            url(
              transformation: {image: {resize: {height: 800}}, document: {output: {format: webp}}}
            )
          }
        }
      }
    `;
    
    const { data: allTeamMemberImages } = useQuery({
      query: getAllTeamMemberImagesQuery
    });
    
    return { allTeamMemberImages };
  },
}
</script>